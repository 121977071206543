<div class="content">
    <app-title text="What did you think?" large="true"></app-title>
    <div class="text">
        So far, the Eden Greenspace community have collectively planted <span class="bold turq-text">{{ trees }}</span> trees! Thank you for being a part of this collective action.
    </div>
    <ng-container *ngIf="!success">
        <div class="text">
            Please let us know what you thought of your Eden Bracelet - it really helps us to make the best possible product for you.
        </div>
        <div class="rate">
            <input type="radio" id="star5" name="rate" [value]="5" [(ngModel)]="review.rating"/>
            <label for="star5" title="text">5 stars</label>
            <input type="radio" id="star4" name="rate" [value]="4" [(ngModel)]="review.rating"/>
            <label for="star4" title="text">4 stars</label>
            <input type="radio" id="star3" name="rate" [value]="3" [(ngModel)]="review.rating"/>
            <label for="star3" title="text">3 stars</label>
            <input type="radio" id="star2" name="rate" [value]="2" [(ngModel)]="review.rating"/>
            <label for="star2" title="text">2 stars</label>
            <input type="radio" id="star1" name="rate" [value]="1" [(ngModel)]="review.rating"/>
            <label for="star1" title="text">1 star</label>
        </div>
        <input class="name" type="text" placeholder="Name (optional)" [(ngModel)]="review.name"/>
        <textarea class="review text" placeholder="Add feedback here" [(ngModel)]="review.review"></textarea>
        <div *ngIf="error" class="text error">{{ error }}</div>
        <button [attr.data-enabled]="!!review.review" (click)="submit()">Submit Review</button>
    </ng-container>
    <ng-container *ngIf="success">
        <div class="text">
            Review submitted! Thank you so much.
        </div>
    </ng-container>
    <br/><br/>
</div>
