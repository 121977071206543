import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

export interface Review {
  rating: number,
  review: string,
  braceletId: string,
  name: string,
}

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  public trees = 0;
  public error: string;
  public success = false;

  public review: Review = {
    rating: 5,
    review: "",
    braceletId: "",
    name: "",
  }

  private authservice: AuthService;
  private route: ActivatedRoute

  constructor(authService: AuthService, route: ActivatedRoute) {
    this.authservice = authService;
    this.route = route;
  }

  ngOnInit() {
    this.authservice.getTotalTrees().subscribe((trees) => this.trees = trees);
    this.review.braceletId = this.route.snapshot.params['braceletId']
  }

  public submit(): void {
    if(!this.review.review) {
      this.error = "Please add some feedback to your review";
    } else {
      this.authservice.addReview(this.review).subscribe(() => {
        this.success = true;
      },
      (res) => {
        this.error = res.error;
      });
    }
  }
}
