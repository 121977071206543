<div *ngIf="this.trees" class="impacts">
    <div class="impact">
        <img class="impact-image" src="../../../assets/trees-impact-logo.png" />
        <div class="impact-value">{{ this.trees | number }}</div>
        <div class="impact-text">Trees Planted</div>
    </div>
    <div class="impact">
        <img class="impact-image" src="../../../assets/rewild-logo.png" />
        <div class="impact-value">{{ this.land | number }}m<sup>2</sup></div>
        <div class="impact-text">Land Rewilded</div>
    </div>
    <div class="impact">
        <img class="impact-image" src="../../../assets/plastic-logo.png" />
        <div class="impact-value">{{ this.plastic | number }}kg</div>
        <div class="impact-text">Plastic Removed</div>
    </div>
    <div class="impact">
        <img class="impact-image" src="../../../assets/co2-logo.png" />
        <div class="impact-value">{{ this.co2 | number}}t</div>
        <div class="impact-text">CO2 Sequestered</div>
    </div>
</div>
