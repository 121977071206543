import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent implements OnInit {
  private location: Location;

  constructor(location: Location) {
    this.location = location  
  }

  public back(): void {
    this.location.back();
  }

  ngOnInit(): void {
  }

}
