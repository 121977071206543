import { Component, Input, OnInit } from '@angular/core';
import { AuthService, Blog } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';
import { TAGS } from '../admin/admin.component';

export function getDate(_id: string, date ?: string): string {
  if (date) {
    return new Date(date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  } else {
    return new Date(parseInt(_id.substring(0, 8), 16) * 1000).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  @Input() fullBlogPage = true;

  private authService: AuthService;
  private seoService: SeoService;

  public getDate = getDate;

  public filterCategories: string[] = [];
  public filterTags: string[] = [];
  public blogCategories: string[] = [];
  public blogTags = TAGS;
  public moreFilters = false;
  public categoryTitle: string;
  public open = false;
  public blogs: Blog[];
  public latest: Blog;
  public isAdmin = false;
  public searchTerm: string;

  constructor(authService: AuthService, seoService: SeoService) { 
    this.authService = authService;
    this.seoService = seoService;
  }

  public ngOnInit(): void {
    if(this.fullBlogPage)
    {
      this.seoService.setSeo('Learn', 'Read about the latest innovations in the sustainability sector, updates on our projects and latest newsletter articles.');
    }

    this.authService.getBlogs(this.filterCategories, this.filterTags, undefined, this.fullBlogPage ? null : 4).subscribe((blogs) => {
      this.blogs = blogs;

      for(let blog of this.blogs) {
        if(!this.blogCategories.includes(blog.category)) {
          this.blogCategories.push(blog.category);
        }
      }
      
    });

    this.authService.getLatestArticle().subscribe((article) => {
      this.latest = article;
    })
    
    this.authService.isAdmin().subscribe((isAdmin) => {this.isAdmin = isAdmin});
  }

  public getDisplayCategory(category: string): string {
    switch (category) {
      case 'newsletters': {
        return 'Newsletter';
      }
      case 'articles': {
        return 'Articles';
      }
      case 'project-updates': {
        return 'Project Update';
      }
      case 'the-team': {
        return 'About Eden';
      }
    }
  }

  public addFilter(category?: string, tag?: string) {
    if(category) {
      if(this.filterCategories.includes(category)) {
        this.filterCategories = [];
      } else {
        this.filterCategories = [category]
      }
    }

    if(tag) {
      if (this.filterTags.includes(tag)) {
        const index = this.filterTags.indexOf(tag);

       this.filterTags.splice(index, 1);
      } else {
        this.filterTags.push(tag);
      }
    }

    this.getBlogs();
  }

  public removeFilters() {
    this.filterCategories = [];
    this.filterTags = [];

    this.getBlogs();
  }

  public search(event: string): void {
    this.searchTerm = event;
    this.getBlogs();
  }

  private getBlogs() {
    this.authService.getBlogs(this.filterCategories, this.filterTags, this.searchTerm).subscribe((blogs) => {
      this.blogs = blogs;
    });
  }
  
}
