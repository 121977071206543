<div class="content">
    <h1>Anti-Greenwashing Methodology</h1>
    <div class="problem-video">
        <div class="problem">
            <div class="text-title italic">“What if saving the planet were as simple as planting a tree?”</div>
            <div class="text-title italic">“Buy carbon offsets and become a carbon neutral business!”</div>
            <div class="text-body">
                <img class="offsetting-image" src="../../../assets/evil-corporations.png"/>
                <div class="text">
                    Sounds familiar, right?
                </div>
                <div class="text">
                    Unfortunately, we cannot just offset our way out of the environmental crisis, and trees are only one small part of restoring our environment.
                </div>
                <div class="text">
                    At Eden Greenspace, we do not endorse these greenwashing slogans.
                </div>
                <div class="text">
                    Instead, our sector-leading <a class="link" href="/articles/anti-greenwashing-methodology" routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</a> ensures that businesses are able to fund a range of
                    environmental projects that are trustworthy, highly-effective, and create quantified impact on the ground, as monitored
                    by our specialist team.
                </div>
                <div class="bold text">
                    This holistic and anti-greenwashing approach to environmental repair is the only long-term, science-based method to
                    restore our planet and limit global warming to 1.5°C in line with the <a class="link" href="https://unfccc.int/process-and-meetings/the-paris-agreement" target="_blank">Paris Agreement</a>.
                </div>
            </div>
            <!--
            <div class="text-body">
                <div>
                    We stand for:  
                </div>
                <div class="bullet-container">
                    <div>
                        <span class="bold trans">Transparency</span> - projects quantified in detail with evidence of your impact. 
                    </div>
                </div>
                <div class="bullet-container">
                    <div>
                        <span class="bold carbon">No carbon offsets</span> - offsets are an excuse not to become more sustainable, and we will never support them.
                    </div>
                </div>
                <div class="bullet-container">
                    <div>
                        <span class="bold global">Global change</span> - there’s no one solution to the planet's crisis, that's why we provide a range of projects. 
                    </div>
                </div>
            </div>
        -->
        </div>
    </div>
    <!--
    <div class="project-images">
        <a class="project-image-container" routerLink="/our-projects/tree-planting-in-madagascar" target="_blank">
            <div class="project-image-text">Plant Trees</div>
            <div class="project-image-text-container">
                <img class="project-image" src="../../../assets/1-1.jpg"/>
                <div class="logos">
                    <img class="logo" src="../../../assets/trees-logo.PNG"/>
                </div>
            </div>
        </a>
        <a class="project-image-container" routerLink="/our-projects/marine-plastic-removal-around-the-uk" target="_blank">
            <div class="project-image-text">Clean Our Oceans</div>
            <div class="project-image-text-container">
                <img class="project-image" src="../../../assets/5-1.jpg"/>
                <div class="logos">
                    <img class="logo" src="../../../assets/plastics-logo.PNG"/>
                </div>
            </div>
        </a>
        <a class="project-image-container" routerLink="/our-projects/rewilding-projects-uk" target="_blank">
            <div class="project-image-text">Wildflower Rewilding</div>
            <div class="project-image-text-container">
                <img class="project-image" src="../../../assets/4-6.jpeg"/>
                <div class="logos">
                    <img class="logo" src="../../../assets/bees-logo.PNG"/>
                </div>
            </div>
        </a>
        <a class="project-image-container" routerLink="/our-projects/solar-light-for-the-world" target="_blank">
            <div class="project-image-text">Solar Lighting</div>
            <div class="project-image-text-container">
                <img class="project-image" src="../../../assets/6-1.png"/>
                <div class="logos">
                    <img class="logo" src="../../../assets/solar-logo.PNG"/>
                </div>
            </div>
        </a>
    </div>
    <div class="info-text">
        Click <a class="link" routerLink="/our-projects" target="_blank">here</a> to find out about the projects and services that we offer. 
    </div>
    -->
    <div class="values">
        <div class="value">
            <img class="value-image" src="../../../assets/credible.png"/>
            <div class="value-title">Trustworthy and Transparent.</div>
            <div class="value-text">Based on the UN’s guidance, our watertight <span class="pointer bold orange underline" routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</span> ensures that we curate and monitor the most trustworthy and effective environmental projects the world has to offer.</div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/greenwashing.png" />
            <div class="value-title">Anti-Greenwashing = Anti-Offsetting.</div>
            <div class="value-text">We support carbon projects and carbon credits, used responsibly. But we don’t encourage companies to make ‘carbon
            neutral’ or ‘carbon offsetting’ claims, as this can distract and delay internal decarbonisation efforts.</div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/global.png" />
            <div class="value-title">A Holistic Approach.</div>
            <div class="value-text">There’s no one solution to restoring our planet. We are the only organisation providing a <span class="pointer bold orange underline" routerLink="our-projects">broad range</span> of the most effective environmental projects.</div>
        </div>
    </div>
    <div class="text-title">Read our full Methodology Document here:</div>
    <button routerLink="/articles/anti-greenwashing-methodology">Anti-Greenwashing Methodology</button>
</div>
<div class="content turquoise">
    <h1 class="brown">Corporate Climate Action Beyond Carbon Offsetting</h1>
    <br/>
    <div class="values">
        <div class="value">
            <img class="value-image" src="../../../assets/business-3.png" />
            <div class="value-title">Help Restore Our Planet</div>
            <div class="value-text">Take direct action to support the world’s most effective and reputable <a class="bold turq-text pointer" routerLink="/our-projects">BVCM nature-based projects</a>, from tree-planting to ocean plastic removal to landscape restoration.</div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/business-1.png" />
            <div class="value-title">Become a Credible Green Business</div>
            <div class="value-text">Trust our UN-backed methodology, and prove your impact with our tailored certificates and green marketing resources. <span class="bold turq-text">Directly fund biodiversity and carbon removal projects.</span></div>
        </div>
        <div class="value">
            <img class="value-image" src="../../../assets/business-2.png" />
            <div class="value-title">Boost Your Sales</div>
            <div class="value-text">Attract new customers and get ahead of competition by demonstrating corporate commitment to real environmental action.</div>
        </div>
    </div>
    <div class="text-title">Become a <span class="bold turq-text">Planetary Restoration Partner</span> by pledging support monthly, or per product.</div>
    <button routerLink="business">View Business Solutions</button>
    <br />
</div>
