<div *ngIf="poll" class="content">
    <app-title text="Sustainability Vote Results"></app-title>
    <ng-container *ngIf="!closed">
        <div class="text">{{ poll.votes.length }} votes counted so far</div>
        <div class="text">Voting will close on {{ formatDate(poll.closes) }}</div>
    </ng-container>
    <ng-container *ngIf="closed">
        <div class="text">Voting closed {{ formatDate(poll.closes) }}</div>
        <div class="text">{{ poll.votes.length }} votes counted</div>
    </ng-container>
    <div class="chart-container">
        <apx-chart 
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [title]="chartOptions.title"
            [legend]="chartOptions.legend"
            [responsive]="chartOptions.responsive"
            [dataLabels]="chartOptions.dataLabels"
            [colors]="chartOptions.colors">
        </apx-chart>
    </div>
</div>
<app-polls [voteEnabled]="false"></app-polls>
<div *ngIf="showEmails" class="content">
    <app-title text="Voters"></app-title>
    <div class="voters">
        <div *ngFor="let voter of getVoters()" class="voter">{{ voter }}</div>
    </div>
</div>
