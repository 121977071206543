<div *ngIf="poll" class="content">
    <ng-container *ngIf="voteEnabled">
        <app-title text="Eden Greenspace Sustainability Vote!"></app-title>
        <div class="subtitle">Choose which environmental project you want {{ poll.companyName }} to support by {{ formatDate(poll.closes) }} using the buttons below.</div>
    </ng-container>
    <div class="options">
        <div *ngFor="let projectId of poll.projects" class="option">
            <img class="option-image" [src]="'../../../assets/' + getImage(projectId)" />
            <div class="option-title">{{ getTitle(projectId) }}</div>
            <div class="bottom">
                <div class="option-text">{{ getText(projectId) }}</div>
                <div class="buttons">
                    <button [routerLink]="getUrl(projectId)">Learn More</button>
                    <button *ngIf="voteEnabled" (click)="vote.projectId = projectId; modal = true">Vote For This Project</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div *ngIf="modal" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content content contact-modal">
        <span class="close pointer" (click)="modal = false">&times;</span>
        <div class="modal-scroll">
            <div class="levels-text">You have selected:</div>
            <span class="levels-text bold">{{ getTitle(vote.projectId) }}</span> 
            <br><br>
            <div class="levels-text">Please use your company email address</div>
            <input class="control" placeholder="Email Address" [(ngModel)]="vote.email"/>
            <button [attr.data-disabled]="!isValid()" (click)="voteInPoll()">Confirm Vote</button>
        </div>
    </div>

</div>