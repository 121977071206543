import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApexChart, ApexNonAxisChartSeries, ApexResponsive, ApexTitleSubtitle, ChartComponent } from 'ng-apexcharts';
import { ApexDataLabels, ApexLegend } from 'ng-apexcharts/public_api';
import { projects } from 'server/projects';
import { Poll } from 'server/types';
import { AuthService } from 'src/app/services/auth.service';
import { getDate } from '../blog/blog.component';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  responsive: ApexResponsive[];
  legend: ApexLegend;
  labels: any;
  dataLabels: ApexDataLabels;
  colors: string[];
};

@Component({
  selector: 'app-poll-results',
  templateUrl: './poll-results.component.html',
  styleUrls: ['./poll-results.component.css']
})
export class PollResultsComponent implements OnInit{
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);

  public getDate = getDate;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  public poll: Poll;
  public closed = false;
  public showEmails = false;

  ngOnInit(): void {
    var pollId = this.route.snapshot.params['pollId'];

    this.authService.getPoll(pollId).subscribe(poll => {
      this.poll = poll;

      this.closed = new Date(poll.closes) < new Date();

      this.chartOptions = {
        series: this.poll.projects.map(projectId => this.poll.votes.filter(vote => vote.projectId === projectId).length),
        chart: {
          width: '100%',
          type: "pie"
        },
        colors: ["#fccf75", "#91cdbc", '#afd396'],
        labels: this.poll.projects.map(projectId => projects.find(project => project.id === projectId).name),
        legend: {
          fontSize: '20%',
          position: "bottom",
          horizontalAlign: 'left',
        },
        dataLabels: {
          style: {
            fontSize: '90%',
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                fontSize: '15%',
              }
            }
          }
        ]
      };
      
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
      
    });

    this.authService.getPlan().subscribe(data => {
      this.showEmails = data[0].poll === pollId;
    });
  }

  public formatDate(date: Date): string {
    return this.getDate("nothing", date.toString());
  }

  public getVoters(): string[] {
    return this.poll.votes.map(v => '• ' + v.email);
  }
}
